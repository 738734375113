const drug = [
  {
    id: "input",
    className: "select-title",
    label: "/ (défaut)",
    value: "title",
  },




  {
    id: "input",
    className: "select-title",
    label: "",
    value: "title",
  },
  {
    id: "input",
    className: "select-title",
    label: "Alcool",
    value: "title",
  },
  {
    id: "input",
    className: "select-item",
    label: "J'ai bu modérément la veille de la séance",
    value: "J'ai bu modérément",
  },
  {
    id: "input",
    className: "select-item",
    label: "J'ai bien étanché ma soif la veille de la séance",
    value: "J'ai bien étanché ma soif",
  },
  {
    id: "input",
    className: "select-item",
    label: "Hier on m'appelait la distillerie, enfin je suis pas sur je m'en rappel pas",
    value: "J'ai trop bu",
  },





  {
    id: "input",
    className: "select-title",
    label: "",
    value: "title",
  },
  {
    id: "input",
    className: "select-title",
    label: "Drogue",
    value: "title",
  },
  {
    id: "input",
    className: "select-item",
    label: "J'ai pris une drogue douce la veille de la séance",
    value: "Drogue douce",
  },
  {
    id: "input",
    className: "select-item",
    label: "J'ai pris une drogue dur la veille de la séance",
    value: "Drogue dur",
  },
  {
    id: "input",
    className: "select-item",
    label: "J'ai vraiment fait n'importe quoi avec trop de trucs",
    value: "Drogues",
  },



  {
    id: "input",
    className: "select-title",
    label: "",
    value: "title",
  },
  {
    id: "input",
    className: "select-title",
    label: "Sexe",
    value: "title",
  },
  {
    id: "input",
    className: "select-item",
    label: "J'ai pratiqué le coït avant la séance (tqt j'te crois)",
    value: "Coït",
  },
  {
    id: "input",
    className: "select-item",
    label: "Je me suis touché avant la séance (je parle pas de massage)",
    value: "Masturbation",
  },
  {
    id: "input",
    className: "select-item",
    label: "Je me suis touché avant la séance en m'empêchant de jouir (tu fais ce que tu veux)",
    value: "Masturbation stoppée",
  },
]

export default drug
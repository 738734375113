const TypeDeProgrammes = [
    { id: "type", label: 'Statique / Figures', value: 'Statique', name: "Type de programme" },
    { id: "type", label: 'Musculation / StreetLifting', value: 'Musculation / StreetLifting', name: "Type de programme" },
    { id: "type", label: 'Poids du corps', value: 'Poids du corps', name: "Type de programme" },
    { id: "type", label: 'Equilibre', value: 'Equilibre', name: "Type de programme" },
    { id: "type", label: 'Haltérophilie', value: 'Haltérophilie', name: "Type de programme" },
    { id: "type", label: 'Strongman', value: 'Strongman', name: "Type de programme" },
    { id: "type", label: 'Bras de fer', value: 'Bras de fer', name: "Type de programme" },
    { id: "type", label: 'Autre', value: 'Autre', name: "Type de programme" },
];

export default TypeDeProgrammes;
const seul = [
  {
    id: "input",
    className: "select-title",
    label: "/ (défaut)",
    value: "title",
  },
  {
    id: "input",
    className: "select-item",
    label: "Seul",
    value: "Seul",
  },
  {
    id: "input",
    className: "select-item",
    label: "Avec des amis - j'ai rigolé",
    value: "Avec des amis - j'ai rigolé",
  },
  {
    id: "input",
    className: "select-item",
    label: "Avec des amis - j'ai tout donné",
    value: "Avec des amis - j'ai tout donné",
  },

]

export default seul;